import { Dialog, LocalStorage, Notify } from 'quasar';

import Authentication from './services/Authentication';
import {
  AuthCredentials,
  LoginResponseData,
  Messages,
  SelfLogin,
} from '../types';
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import NotifyHelper from './NotifyHelper';
import User from './services/User';
import Area from './services/Area';

export default class Auth {
  public static async authenticateUser({
    platform,
    email,
    resource,
    hash,
    integration,
  }: AuthCredentials) {
    try {
      if (resource === 'self') {
        const payload: SelfLogin = {
          platform,
          hash,
        };

        if (integration) {
          payload.integration = integration;
        } else {
          payload.email = email;
        }

        const loginResponse: LoginResponseData = await Authentication.self(
          payload
        );
        if (loginResponse) {
          if (loginResponse.data.token) {
            Authentication.storeUser(loginResponse.data);
            /* Here we can set headers Authorization token
              //eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              api.defaults.headers['Authorization'] = `Bearer ${data.token}`
            */
            return loginResponse.data;
          } else {
            NotifyHelper.notifyError(Messages.LOGOUT_FAIL);
          }
        } else {
          if (integration) {
            NotifyHelper.notifyError(Messages.INTEGRATION_LOGIN_ERROR);
          } else {
            NotifyHelper.notifyError(Messages.WRONG_PASSWORD);
          }
        }
      }
    } catch (err) {
      if (integration) {
        Notify.create({
          timeout: 10000,
          progress: true,
          multiLine: true,
          message: Messages.GENERIC_LOGIN_ERROR,
          caption: Messages.INTEGRATION_LOGIN_ERROR,
        });
      } else {
        NotifyHelper.notifyError(Messages.GENERIC_LOGIN_ERROR);
      }
      throw new Error(err as string);
    }
  }

  public static async integrationLogin(token: string) {
    try {
      const loginResponse: LoginResponseData = await Authentication.integration(
        token
      );
      if (loginResponse) {
        if (loginResponse.data.token) {
          Authentication.storeUser(loginResponse.data);
          /* Here we can set headers Authorization token
              //eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              api.defaults.headers['Authorization'] = `Bearer ${data.token}`
            */
          return loginResponse.data;
        } else {
          NotifyHelper.notifyError(Messages.LOGOUT_FAIL);
        }
      } else {
        NotifyHelper.notifyError(Messages.WRONG_PASSWORD);
      }
    } catch (err) {
      NotifyHelper.notifyError(Messages.GENERIC_LOGIN_ERROR);
      throw new Error(err as string);
    }
  }

  public static logout() {
    LocalStorage.clear();
    // Here we clear default headers Authorization and store data
    location.reload();
  }

  public static requireAuth(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    if (!Authentication.loggedUser()) {
      next('/login');
      return;
    } else {
      Authentication.hasRoles(to, next);
    }
  }

  public static redirectIfAuth(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    if (!!Authentication.loggedUser()) {
      next(Authentication.firstView());
      return;
    } else {
      next();
      return;
    }
  }
  public static hasRegisterModule(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const area = Area.loggedArea();
    if (!area.modules.includes('cadastrar')) return next('/login');
    next();
  }
  public static hasFormModule(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const area = Area.loggedArea();
    if (!area.modules.includes('form-register')) return next('/login');
    next();
  }
  private static invalidTokenError(next: NavigationGuardNext): void {
    next('/login');
    Dialog.create({
      title: 'Token inválido',
      message: 'O token informado é inválido ou expirou.',
      noRouteDismiss: true,
    });
    return;
  }
  public static async resetPasswordAllowed(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    try {
      const { id, token } = to.params;

      if (!id || !token) {
        next('/login');
        return;
      }

      const user = await User.getById(id as string);
      const { _etag } = user;

      if (_etag !== token) return this.invalidTokenError(next);
      next();
    } catch (error) {
      this.invalidTokenError(next);
    }
  }
}
