/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AxiosRequestConfig } from 'axios';
import { api, authApi } from '../axios';
import { LocalStorage } from 'quasar';
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import AreaService from './Area';
import { Dialog } from 'quasar';
import Auth from '../auth';
import {
  LoginResponseObject,
  SelfLogin,
  Messages,
  AppMenuItem,
  LoginResponseData,
} from '../../types';
import { useUser } from 'src/pinia/useUser';
import NotifyHelper from '../NotifyHelper';

type SavePasswordParams = {
  password: string;
  id: string;
  token: string;
};

interface AxiosRequestConfigWithToken extends AxiosRequestConfig {
  headers?: {
    token?: string;
    pswdtk?: string;
  };
  data?: {
    integration?: string;
    email?: string;
    area?: string;
    platform?: string;
    group?: string;
    token?: string;
  };
}

const AUTH_ENDPOINT = '/';
const AUTH_TOKEN_ENDPOINT = '/token';
const AUTH_TOKEN_INTEGRATION = '/integration';
const RESET_PASSWORD_ENDPOINT = '/email/password';
const UPDATE_USER_ENDPOINT = '/user/admin';
export default class Authentication {
  public static async self({
    email,
    platform,
    hash,
    integration,
  }: SelfLogin): Promise<LoginResponseData> {
    const { name, group } = AreaService.loggedArea();
    const config: AxiosRequestConfigWithToken = {
      url: AUTH_ENDPOINT,
      method: 'POST',
    };

    if (integration) {
      config.headers = {
        token: hash,
      };
      config.url = AUTH_TOKEN_ENDPOINT;
    } else {
      config.headers = {
        pswdtk: hash,
      };
      config.data = {
        email,
        area: name,
        platform,
        group,
      };
    }
    const response: LoginResponseData = await authApi.request(config);
    return response;
  }

  public static async integration(token: string): Promise<LoginResponseData> {
    const config: AxiosRequestConfigWithToken = {
      url: AUTH_TOKEN_INTEGRATION,
      method: 'POST',
    };

    config.data = {
      token: token,
    };
    const response: LoginResponseData = await authApi.request(config);
    return response;
  }

  public static storeUser(loginData: LoginResponseObject) {
    const userStore = useUser();
    userStore.user = loginData;
    LocalStorage.set('user', loginData);
  }

  public static removeUser() {
    LocalStorage.remove('user');
  }

  public static isAuthorized() {
    const sessionData = LocalStorage.getItem('user');
    if (!sessionData) {
      NotifyHelper.notifyError(Messages.SESSION_EXPIRED);
      Auth.logout();
      return;
    }
  }

  public static loggedUser(): LoginResponseObject | undefined {
    const sessionData = LocalStorage.getItem('user');
    if (sessionData) {
      return sessionData as LoginResponseObject;
    }
  }

  public static hasRoleBySlug(slugs: string[]) {
    const user = Authentication.loggedUser();
    if (!user) return false;
    if (slugs?.length) {
      return user.modules.some((m) => slugs.includes(m.slug));
    }
    return false;
  }

  public static hasRoles(
    to: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const user = Authentication.loggedUser();
    if (!user) return false;
    const permissions = user.modules.filter((el) => {
      return user.permissions[0].modules.includes(el.slug);
    });
    const exceptions = ['/', '/login', '/destaques', '/bem-vindo', '/gratuito'];
    const basePath = `/${to.fullPath.split('/')[1]}`;
    if (exceptions.includes(basePath)) {
      next();
      return;
    }
    if (permissions.length && permissions.find((el) => el.link === basePath)) {
      next();
      return;
    }
    next('/');
  }

  public static firstView(): string {
    const user = Authentication.loggedUser();
    if (!user) return '/login';
    const modules = this.menu();
    return modules[0].link;
  }

  public static menu(): AppMenuItem[] {
    const loginData = Authentication.loggedUser();
    if (!loginData) return [];

    const modules = loginData.modules;
    const permissionModules = loginData.permissions[0].modules;
    const areaModules = loginData.area.modules;
    const moduleList = areaModules
      .filter((slug) => permissionModules.includes(slug))
      .filter((slug) =>
        modules.find(
          (module) => module.slug === slug && module.place === 'menu'
        )
      );
    const menuList: AppMenuItem[] = moduleList.map((slug) => {
      const module = modules.find((module) => module.slug === slug);
      return {
        title: module?.name || '',
        icon: module?.icon || '',
        link: module?.link || '',
        slug: module?.slug || '',
      };
    });
    return menuList;
  }

  public static async resetPassword(email: string): Promise<boolean> {
    const { name, group } = AreaService.loggedArea();
    if (!name || !group) return false;
    return await api
      .post(
        RESET_PASSWORD_ENDPOINT,
        {
          email,
          host: `${window.location.origin}/reset_password`,
          from_social: false,
          area: name,
        },
        {
          headers: {
            'X-Org-Id': group,
            UserEmail: email,
          },
        }
      )
      .then(() => {
        Dialog.create({
          title: Messages.RESET_PASSWORD_EMAIL_SUCCESS_TITLE,
          message: Messages.RESET_PASSWORD_EMAIL_SUCCESS_MESSAGE,
        });
        return true;
      })
      .catch(() => {
        Dialog.create({
          title: Messages.RESET_PASSWORD_EMAIL_ERROR_TITLE,
          message: Messages.RESET_PASSWORD_EMAIL_ERROR_MESSAGE,
        });
        return false;
      });
  }
  public static async saveNewPassword({
    password,
    id,
    token,
  }: SavePasswordParams): Promise<boolean> {
    try {
      await api.patch(
        `${UPDATE_USER_ENDPOINT}/${id}`,
        {},
        {
          headers: {
            'If-Match': token,
            pswdtk: password,
          },
        }
      );
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}
