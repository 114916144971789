import { RouteRecordRaw } from 'vue-router';
import Auth from '../boot/auth';
import Authentication from '../boot/services/Authentication';

type SubjectPageParams = {
  params: {
    subject?: string;
    topic?: string;
    id: string;
  };
};

type SimuladoPageParams = {
  params: {
    id: string;
  };
};

type EvaluationPageParams = {
  params: {
    subject?: string;
    id?: string;
  };
};

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    beforeEnter: (to, from, next) => Auth.requireAuth(to, from, next),
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        redirect: () => {
          return {
            path: Authentication.firstView(),
          };
        },
      },
      {
        path: '/subscribe',
        component: () => import('pages/Subscribe.vue'),
        meta: {
          title: 'Assinatura',
        },
      },
      {
        path: '/estatisticas',
        component: () => import('pages/StatisticPage.vue'),
        meta: {
          title: 'Minhas Estatísticas',
        },
      },
      {
        path: '/bem-vindo',
        component: () => import('pages/IntroDialog.vue'),
        meta: {
          title: 'Bem-Vindo',
        },
      },
      {
        path: '/destaques',
        component: () => import('pages/HightLight.vue'),
        meta: {
          title: 'Destaques',
        },
      },
      {
        path: '/gratuito',
        component: () => import('pages/HightLightTest.vue'),
        meta: {
          title: 'Gratuito',
        },
      },
      {
        path: '/editais',
        component: () => import('pages/ChangeContest.vue'),
        meta: {
          title: 'Meus Editais',
        },
      },
      {
        path: '/revisao',
        component: () => import('pages/ReviewQuestions.vue'),
        meta: {
          title: 'Revisão de Questões',
        },
      },
      {
        path: '/questoes',
        component: () => import('pages/FreeObjectives.vue'),
        meta: {
          title: 'Questões Livres',
          helpIcon: true,
          helpText:
            'Aqui você pode escolher e praticar livremente com questões de diversos concursos, disciplinas e todos os assuntos do nosso banco de questões, sem o pré-filtro do edital. Use os filtros para selecionar questões mais adequadas para seu momento de estudo.',
        },
        children: [
          {
            path: '',
            component: () =>
              import('../components/free-objectives/ObjectiveFilter.vue'),
            meta: {
              title: 'Questões Livres',
            },
          },
        ],
      },
      {
        path: '/objetivas',
        component: () => import('pages/Objectives.vue'),
        meta: {
          title: 'Questões Objetivas',
          helpIcon: true,
          helpText:
            'Esse módulo oferece exclusivamente questões para cada disciplina do edital do concurso que você está se preparando. As questões foram selecionadas cuidadosamente para garantir que você possa se concentrar nos assuntos mais relevantes para sua preparação.',
        },
        children: [
          {
            path: ':subject?/:topic?',
            component: () =>
              import('../components/objectives/ObjectiveFilter.vue'),
            meta: {
              title: 'Questões Objetivas',
            },
            props: (route: SubjectPageParams) => ({
              subject: route.params.subject,
              topic: route.params.topic,
            }),
          },
        ],
      },
      {
        path: '/exercicios',
        component: () => import('pages/Objectives.vue'),
        meta: {
          title: 'Exercícios',
        },
        children: [
          {
            path: ':subject?/:topic?',
            component: () =>
              import('../components/objectives/ObjectiveFilter.vue'),
            meta: {
              title: 'Exercícios',
            },
            props: (route: SubjectPageParams) => ({
              subject: route.params.subject,
              topic: route.params.topic,
            }),
          },
        ],
      },
      {
        path: '/discursivas',
        component: () => import('pages/Discursives.vue'),
        meta: {
          title: 'Questões Discursivas',
        },
        children: [
          {
            path: ':subject?/:topic?',
            component: () =>
              import('../components/discursives/DiscursiveFilter.vue'),
            meta: {
              title: 'Questões Discursivas',
            },
            props: (route: SubjectPageParams) => ({
              subject: route.params.subject,
              topic: route.params.topic,
            }),
          },
        ],
      },
      {
        path: '/minha-jornada',
        component: () => import('pages/Journey.vue'),
        name: 'Minha Jornada',
        meta: { title: 'Minha Jornada' },
      },
      {
        path: '/material',
        component: () => import('pages/Materials.vue'),
        name: 'base_material',
        children: [
          {
            path: ':subject?',
            component: () =>
              import('../components/materials/subject/SubjectList.vue'),
            name: 'material_subject_list',
            props: (route: SubjectPageParams) => ({
              subject: route.params.subject,
            }),
            meta: {
              title: 'Disciplinas',
            },
          },
          {
            path: ':subject/:id',
            component: () =>
              import('../components/materials/MaterialPreview.vue'),
            name: 'material_subject_id',
            props: (route: SubjectPageParams) => ({
              subject: route.params.subject,
              id: route.params.id,
            }),
            meta: {
              title: 'Material',
            },
          },
        ],
      },
      {
        path: '/metodologia',
        component: () => import('pages/Methodology.vue'),
        name: 'base_methodology',
        children: [
          {
            path: ':subject?',
            component: () =>
              import('../components/materials/subject/SubjectList.vue'),
            name: 'methodology_subject_list',
            props: (route: SubjectPageParams) => ({
              subject: route.params.subject,
            }),
            meta: {
              title: 'Metodologia',
            },
          },
          {
            path: ':subject/:id',
            component: () =>
              import('../components/materials/MaterialPreview.vue'),
            name: 'methodology_subject_id',
            props: (route: SubjectPageParams) => ({
              subject: route.params.subject,
              topic: route.params.topic,
              id: route.params.id,
            }),
            meta: {
              title: 'Metodologia',
            },
          },
        ],
      },
      {
        path: '/controle',
        component: () => import('src/pages/StudyControl.vue'),
        meta: {
          title: 'Controle de Estudos',
        },
      },
      {
        path: '/controle/novo',
        component: () => import('src/pages/StudyControl.vue'),
        meta: {
          title: 'Controle de Estudos',
        },
      },
      {
        path: 'simulado',
        component: () => import('src/pages/Simulations.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('../components/simulations/SimulationIntro.vue'),
            meta: {
              title: 'Simulados',
              helpIcon: true,
              helpText:
                'Os simulados gerados pela plataforma são personalizados e apresentam questões de acordo com o sua proficiência no Diagnóstico. A distribuição das questões respeita o último edital publicado, assim como o tempo do cronômetro na barra superior.  Você pode salvar um simulado para terminá-lo posteriormente e conferir o gabarito após sua conclusão.',
            },
          },
          {
            path: 'novo',
            component: () =>
              import('../components/simulations/SimulationCreate.vue'),
            meta: {
              title: 'Novo Simulado',
            },
          },
          {
            path: ':id',
            component: () =>
              import('../components/simulations/SimulationCreate.vue'),
            props: (route: SimuladoPageParams) => ({
              id: route.params.id,
            }),
            meta: {
              title: 'Simulado',
            },
          },
          {
            path: 'gabarito/:id',
            component: () =>
              import('../components/simulations/SimulationHistoryTest.vue'),
            props: (route: SimuladoPageParams) => ({
              id: route.params.id,
            }),
            meta: {
              title: 'Gabarito Simulado',
            },
          },
        ],
      },
      {
        path: 'provas',
        component: () => import('src/pages/Quiz.vue'),
        children: [
          {
            path: '',
            component: () => import('../components/quiz/QuizIntro.vue'),
            meta: {
              title: 'Provas',
              helpIcon: true,
              helpText:
                'Aqui você encontra provas de concursos anteriores e provas exclusivas desenvolvidas por nossos professores. Você pode salvar uma prova para terminá-la posteriormente e conferir o gabarito após sua conclusão.',
            },
          },
          {
            path: ':id',
            component: () => import('../components/quiz/QuizCreate.vue'),
            props: (route: SimuladoPageParams) => ({
              id: route.params.id,
            }),
            meta: {
              title: 'Provas',
              isFeedback: false,
            },
          },
          {
            path: 'gabarito/:id',
            component: () => import('../components/quiz/QuizCreate.vue'),
            props: (route: SimuladoPageParams) => ({
              id: route.params.id,
            }),
            meta: {
              title: 'Gabarito Provas',
              isFeedback: true,
            },
          },
        ],
      },
      {
        path: 'avaliacao',
        component: () => import('pages/Evaluations.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('../components/evaluations/EvaluationsFilter.vue'),
            meta: {
              title: 'Avaliações',
            },
          },
          {
            path: ':subject',
            component: () =>
              import('../components/evaluations/EvaluationTest.vue'),
            props: (route: EvaluationPageParams) => ({
              subject: route.params.subject,
            }),
            meta: {
              title: 'Avaliação',
            },
          },
          {
            path: 'historico/:subject',
            component: () =>
              import('../components/evaluations/EvaluationHistory.vue'),
            props: (route: EvaluationPageParams) => ({
              subject: route.params.subject,
            }),
            meta: {
              title: 'Histórico de Avaliações',
            },
          },
          {
            path: ':subject/:id',
            component: () =>
              import('../components/evaluations/EvaluationFeedback.vue'),
            props: (route: EvaluationPageParams) => ({
              subject: route.params.subject,
              id: route.params.id,
            }),
            meta: {
              title: 'Avaliação - Gabarito',
            },
          },
        ],
      },
      {
        path: 'diagnostico',
        component: () => import('pages/Evaluations.vue'),
        children: [
          {
            path: '',
            component: () =>
              import('../components/evaluations/EvaluationsFilter.vue'),
            meta: {
              title: 'Diagnóstico',
            },
          },
          {
            path: ':subject',
            component: () =>
              import('../components/evaluations/EvaluationTest.vue'),
            props: (route: EvaluationPageParams) => ({
              subject: route.params.subject,
            }),
            meta: {
              title: 'Diagnóstico',
            },
          },
          {
            path: 'historico/:subject',
            component: () =>
              import('../components/evaluations/EvaluationHistory.vue'),
            props: (route: EvaluationPageParams) => ({
              subject: route.params.subject,
            }),
            meta: {
              title: 'Diagnóstico',
            },
          },
          {
            path: ':subject/:id',
            component: () =>
              import('../components/evaluations/EvaluationFeedback.vue'),
            props: (route: EvaluationPageParams) => ({
              subject: route.params.subject,
              id: route.params.id,
            }),
            meta: {
              title: 'Diagnóstico',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/formulario',
    component: () => import('layouts/FormLayout.vue'),
    children: [
      { path: '', component: () => import('pages/Form.vue') },
      {
        path: 'cadastro-realizado',
        component: () => import('pages/Thanks.vue'),
      },
    ],
    meta: {
      title: 'Simulado Online PMERJ',
    },
  },
  {
    path: '/login',
    beforeEnter: (to, from, next) => {
      if (to.query.token) {
        // Se o token existe, não faz nada
        next();
      } else {
        // Caso contrário, chama a função de redirecionamento
        Auth.redirectIfAuth(to, from, next);
      }
    },
    component: () => import('layouts/LoginLayout.vue'),
    children: [{ path: '', component: () => import('pages/Login.vue') }],
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/redefinir-senha',
    component: () => import('layouts/LoginLayout.vue'),
    children: [
      { path: '', component: () => import('pages/ResetPassword.vue') },
    ],
    meta: {
      title: 'Redefinir Senha',
    },
  },
  {
    path: '/auth',
    beforeEnter: (to, from, next) => Auth.redirectIfAuth(to, from, next),
    component: () => import('layouts/LoginLayout.vue'),
    children: [{ path: '', component: () => import('pages/ExternalAuth.vue') }],
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/reset_password/:id/:token',
    component: () => import('layouts/LoginLayout.vue'),
    children: [
      {
        path: '',
        beforeEnter: async (to, from, next) =>
          await Auth.resetPasswordAllowed(to, from, next),
        component: () => import('pages/RetrievePassword.vue'),
        props: (route) => ({
          id: route.params.id,
          token: route.params.token,
          user: route.params.user,
        }),
        meta: {
          title: 'Redefinição de Senha',
          origin: 'reset_password',
        },
      },
    ],
  },
  {
    path: '/validate_email/:id/:token',
    component: () => import('layouts/LoginLayout.vue'),
    children: [
      {
        path: '',
        beforeEnter: async (to, from, next) =>
          await Auth.resetPasswordAllowed(to, from, next),
        component: () => import('pages/ValidateEmail.vue'),
        props: (route) => ({
          id: route.params.id,
          token: route.params.token,
        }),
        meta: {
          title: 'Confirmação de Email',
          origin: 'validate_email',
        },
      },
    ],
  },
  {
    path: '/external_confirmation/:id/:token',
    component: () => import('layouts/LoginLayout.vue'),
    children: [
      {
        path: '',
        beforeEnter: async (to, from, next) =>
          await Auth.resetPasswordAllowed(to, from, next),
        component: () => import('pages/RetrievePassword.vue'),
        props: (route) => ({
          id: route.params.id,
          token: route.params.token,
        }),
        meta: {
          title: 'Confirmação de Senha',
          origin: 'external_confirmation',
        },
      },
    ],
  },
  {
    path: '/cadastro',
    beforeEnter: (to, from, next) => Auth.hasRegisterModule(to, from, next),
    component: () => import('layouts/LoginLayout.vue'),
    children: [{ path: '', component: () => import('pages/RegisterPage.vue') }],
    meta: {
      title: 'Realizar Cadastro',
    },
  },
  {
    path: '/lostArea',
    component: () => import('pages/Error404.vue'),
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue'),
  },
];

export default routes;
